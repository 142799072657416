import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, AnswerSelection } from '../../input';
import ImportanceToggle from './ImportanceToggle';
import { setAnswer } from '../../../actions';
import { SmallText, BigText } from '../../text';
import { VisuallyHidden } from '../../meta';

const Container = styled.div`
  position: relative;
  padding: 2rem;
`;

const QuestionIndex = styled(SmallText)`
  display: inline-block;
  text-align: right;
  font-weight: bold;
  margin: 0;
  width: 100%;
`;

const QuestionText = styled(BigText)`
  min-height: 8rem;
  margin: 1rem auto 0;
`;

const NavButton = styled(Button)`
  position: fixed;
  box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
  top: 50%;
  padding: 2.5rem .125rem;
  transform: translateY(-50%);
  font-size: 2rem;
  ${props => props.alignment}: .5rem;
  background: #eee;
  color: black;
  font-weight: normal;
  z-index: 2;
  
  @media (hover: none) {
    &:hover {
      background: #eee;
    }
  }
  
  @media (min-width: 50rem) {
    position: absolute;
    box-shadow: none;
  }
`;

const NavSVG = styled.svg`
  width: .5rem;
  padding: 0 .25rem;
  
  path {
    fill: none;
    stroke: black;
    stroke-width: 15;
    stroke-linejoin: round;
    stroke-linecap: round;
  }
`;

export class Question extends Component {
  static propTypes = {
    className: PropTypes.string,
    questionIndex: PropTypes.number.isRequired,
    question: PropTypes.string.isRequired,
    questionsCount: PropTypes.number.isRequired,
    answer: PropTypes.oneOf(['approval', 'negative', 'neutral', 'skipped']).isRequired,
    setAnswer: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: null,
  };

  handleKeyDown = ({ keyCode }) => {
    const { push, questionIndex, questionsCount } = this.props;
    const showPrev = questionIndex > 0;
    const showNext = questionIndex < questionsCount - 1;
    if (keyCode === 37 && showPrev) {
      push(`/questions/${questionIndex}`)
    }
    if (keyCode === 39 && showNext) {
      push(`/questions/${questionIndex + 2}`)
    }
  };

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  render() {
    const { questionIndex, question, questionsCount, answer, setAnswer, push, className } = this.props;
    const showPrev = questionIndex > 0;
    const showNext = questionIndex < questionsCount - 1;
    return (
      <Container className={className}>
        <QuestionIndex>{questionIndex + 1}/{questionsCount}</QuestionIndex>
        {showPrev && (
          <NavButton to={`/questions/${questionIndex}`} alignment="left">
            <NavSVG aria-hidden="true" viewBox="0 0 50 100">
              <path d="M40,10 L10,50 L40,90"/>
            </NavSVG>
            <VisuallyHidden>zurück</VisuallyHidden>
          </NavButton>
        )}
        {showNext && (
          <NavButton to={`/questions/${questionIndex + 2}`} alignment="right">
            <NavSVG aria-hidden="true" viewBox="0 0 50 100">
              <path d="M10,10 L40,50 L10,90"/>
            </NavSVG>
            <VisuallyHidden>weiter</VisuallyHidden>
          </NavButton>
        )}

        <QuestionText>
          {question}
        </QuestionText>

        <ImportanceToggle questionIndex={questionIndex}/>
        <AnswerSelection
          value={answer}
          onChange={value => {
            setAnswer(questionIndex, value);
            showNext && push(`/questions/${questionIndex + 2}`);
          }}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ questions, answers }, { questionIndex }) => {
  const { question } = questions[questionIndex];
  return {
    question,
    questionsCount: questions.length,
    answer: answers[questionIndex].answer,
  }
};

const mapDispatchToProps = dispatch => ({
  setAnswer: (index, value) => dispatch(setAnswer(index, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Question);