import styled from 'styled-components';

export const H1 = styled.h1`
  margin: 2rem auto 1rem;
  font-size: 1.5rem;
  
  &:first-child {
    margin-top: 0;
  }
  
  @media (min-width: 50rem) {
    font-size: 2rem;
  }
`;

export const H2 = styled.h2``;

export const H3 = styled.h3``;