import { SET_QUESTIONS } from '../actions';

/*
  shape
  [
    {
      question:String
      answers: [
        party:String
        answer:(approval|negative|neutral)
        reason:String
      ]
    },
    ...
  ]
 */
const defaultState = [];

const questions = (state = defaultState, { type, questions }) => {
  switch (type) {
    case SET_QUESTIONS:
      return questions;
    default:
      return state;
  }
};

export default questions;