import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from './index';

const Selector = styled(Button)`
  width: calc(100% / 3 - 1rem);
  background: ${props => props.action && (props.theme.color)[props.action]};
  
  &:focus,
  &:hover {
    color: white;
    background: ${props => props.action && ({
      approval: '#32891f',
      neutral: '#505050',
      negative: '#a73e1f',
    })[props.action]};
  }
  
  @media (hover: none) {
    &:hover {
      background: ${props => props.action && (props.theme.color)[props.action]};
    }
  }

  ${props => props.ariaSelected && `
    border-color: red;
    &::before {
      content: '✓ ';
      margin: 0 0 0 -.5rem;
    }
  `};
`;

const Container = styled.div`
  width: 100%;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-content: space-between;
  flex-direction: column;
  
  > * {
    margin: .25rem 0;
    width: 100%;
  
    &:first-child {
      margin-top: 0;
    }
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  @media (min-width: 30rem) {
    flex-direction: row;
    
    > * {
      margin: 0 1rem;
  
      &:first-child {
        margin-left: 0;
      }
      
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const SkipperContainer = styled.div`
  margin: 1rem 0 0;
  text-align: right;
`;

const SkipButton = styled.button`
  color: black;
  padding: 0;
  border: 0;
  background: transparent;
  font-size: .875rem;
  
  &:hover {
    color: ${props => props.theme.color.europeBlue};
  }
  
  @media (hover: none) {
    &:hover {
      color: black;
    }
  }
  
  :not(:disabled) {
    cursor: pointer;
  }
`;

const AnswerSelection = ({ onChange, value }) => {
  const handleClick = newValue => () => onChange(newValue);
  return (
    <Container>
      <ButtonContainer>
        <Selector
          onClick={handleClick('approval')}
          ariaSelected={value === 'approval'}
          action="approval"
        >
          Stimme zu
        </Selector>
        <Selector
          onClick={handleClick('neutral')}
          ariaSelected={value === 'neutral'}
          action="neutral"
        >
          Neutral
        </Selector>
        <Selector
          onClick={handleClick('negative')}
          ariaSelected={value === 'negative'}
          action="negative"
        >
          Stimme nicht zu
        </Selector>
      </ButtonContainer>
      <SkipperContainer>
        <SkipButton
          onClick={handleClick('skipped')}
        >
          Keine Meinung / nicht werten ⇢
        </SkipButton>
      </SkipperContainer>
    </Container>
  );
};

AnswerSelection.propTypes = {
  value: PropTypes.oneOf(['approval', 'negative', 'neutral', 'skipped']).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AnswerSelection;