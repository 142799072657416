import biotifWOFF2 from './biotif/biotif.woff2';
import biotifWOFF from './biotif/biotif.woff';
import biotifBoldWOFF2 from './biotif/biotif-bold.woff2';
import biotifBoldWOFF from './biotif/biotif-bold.woff';
import biotifBlackWOFF2 from './biotif/biotif-black.woff2';
import biotifBlackWOFF from './biotif/biotif-black.woff';

const combine = (woff, woff2) => ({ woff, woff2 });

const biotif = combine(biotifWOFF, biotifWOFF2);
const biotifBold = combine(biotifBoldWOFF, biotifBoldWOFF2);
const biotifBlack = combine(biotifBlackWOFF, biotifBlackWOFF2);

export {
  biotif,
  biotifBold,
  biotifBlack,
};