import styled from 'styled-components';

export const BigText = styled.p`
  font-size: 1.25rem;
  line-height: 1.4;
  margin: 0 auto 0;
  
  @media (min-width: 30rem) {
    font-size: 1.5rem;
  }
`;

export default BigText;