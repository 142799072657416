import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SmallText } from '../text';

const Container = styled.div`
  margin: 0 0 2rem;
`;

// heh, "party line"...
const PartyLine = styled.div`
  //display: flex;
  width: 100%;
  
  & + & {
    margin-top: .25rem;
  }
`;

const PartyName = styled(SmallText)`
  display: inline-block;
  width: 10rem;
  margin: 0;
  font-weight: bold;
`;

const PartyMatch = styled.div`
  position: relative;
  flex-grow: 1;
  box-sizing: border-box;
  padding: 0 .25rem;
  
  &::before {
    content: ' ';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: ${props => props.theme.color.europeYellow};
    transform: scaleX(${props => Math.max(props.match, .005)});
    transform-origin: 0 0;
    transition: transform 200ms ease-out;
    border-radius: 3px;
  }
`;

const FormattedMatch = styled.span`
  position: relative;
  z-index: 1;
`;

export const Results = ({ results }) => (
  <Container>
    {results.map(({ formattedMatch, partyName, match }) => (
      <PartyLine key={partyName}>
        <PartyName>{partyName}</PartyName>
        <PartyMatch match={match}>
          <FormattedMatch>{formattedMatch}</FormattedMatch>
        </PartyMatch>
      </PartyLine>
    ))}
  </Container>
);

Results.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      match: PropTypes.number.isRequired, // a value between 0 and 1
      partyName: PropTypes.string.isRequired,
      formattedMatch: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

const mapStateToProps = ({ answers, questions, parties }) => ({
  results: parties
    .map(partyName => {
      const partyAnswers = questions
        .map(({ answers }) => answers
          .find(({ party }) => party === partyName)
        )
        .map(({ answer }) => answer);

      const totalQuestionsValue = answers.reduce((acc, { answer, double }) => {
        if (answer === 'skipped') return acc;
        return acc + (double ? 4 : 2);
      }, 0);
      const totalMatchingAnswersValue = answers
        .reduce((acc, { answer, double }, index) => {
          const partyAnswer = partyAnswers[index];
          if (answer === 'skipped') return acc;
          if (answer === partyAnswer) return  acc + (double ? 4 : 2);
          if (answer === 'neutral' || partyAnswer === 'neutral') return  acc + (double ? 2 : 1);
          return acc;
        }, 0);
      const match = totalQuestionsValue === 0
        ? 0
        :  totalMatchingAnswersValue / totalQuestionsValue;
      const formattedMatch = Math.round(match * 1000) / 10 + '%';

      return {
        partyName,
        match,
        formattedMatch,
      };
    })
    .sort(({ match: a }, { match: b }) => b - a),
});

export default connect(mapStateToProps)(Results);