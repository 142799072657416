import React from 'react';

import ComponentDoc from './ComponentDoc';
import { H1, H2, H3, Paragraph } from '../../text';
import { Button } from '../../input';
import { Overview } from '../Questionnaire/Overview';

export const ComponentLibrary = () => (
  <div>
    <h1>Components</h1>

    <ComponentDoc
      code={`<H1>Headline 1</H1>`}
      node={<H1>Headline 1</H1>}
    >
      Some documentation for this component.
    </ComponentDoc>

    <ComponentDoc
      code={`<H2>Headline 1</H2>`}
      node={<H2>Headline 1</H2>}
    />

    <ComponentDoc
      code={`<H3>Headline 1</H3>`}
      node={<H3>Headline 1</H3>}
    />

    <ComponentDoc
      code={`<Paragraph>
  This is a paragraph
</Paragraph>`}
      node={<Paragraph>This is a paragraph</Paragraph>}
    />

    <ComponentDoc
      code={`<Button>Click me</Button>`}
      node={<Button>Click me</Button>}
    />

    <ComponentDoc
      code={`<Overview
  index={2}
  answers={[
    {
      double: true,
      answer: 'skipped',
    },
    {
      double: false,
      answer: 'approval',
    },
    {
      double: false,
      answer: 'neutral',
    },
    {
      double: false,
      answer: 'negative',
    },
  ]}
/>`}
      node={<Overview index={2} answers={[
        {
          double: true,
          answer: 'skipped',
        },
        {
          double: false,
          answer: 'approval',
        },
        {
          double: false,
          answer: 'neutral',
        },
        {
          double: false,
          answer: 'negative',
        },
      ]} />}
    />
  </div>
);

export default ComponentLibrary;