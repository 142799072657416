import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Paragraph } from '../text';

const PartyAnswersContainer = styled.div`
  margin: 0 0 2rem;
`;

const PartyName = styled.span`
  display: inline-block;
  margin: 0 auto 0 .5rem;
  font-weight: bold;
`;

const Reason = styled.div`
  padding-top: 1rem;

  & + & {
    border-top: 1px solid #bbb;
    margin-top: 1rem;
  }
`;

const PartyNameContainer = styled(Paragraph)`
  margin: 0 auto;
`;

const ReasonContainer = styled(Paragraph)`
  margin-top: .25rem;
`;

const PartyAnswers = ({ answers }) => (
  <PartyAnswersContainer>
    <strong>Begründungen:</strong>
    {answers.map(({ partyName, answer, reason }) => (
      <Reason key={partyName}>
        <PartyNameContainer>
          {{
            approval: '✅',
            neutral: '🤷‍♂️',
            negative: '❌',
            skipped: '💭'
          }[answer]}
          <PartyName>{partyName}</PartyName>
        </PartyNameContainer>
        <ReasonContainer>
          {reason}
        </ReasonContainer>
      </Reason>
    ))}
  </PartyAnswersContainer>
);

PartyAnswers.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      partyName: PropTypes.string.isRequired,
      answer: PropTypes.oneOf([ 'approval', 'neutral', 'negative' ]),
      reason: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  questionIndex: PropTypes.number.isRequired,
};

const mapStateToProps = ({ parties, questions }, { questionIndex }) => ({
  answers: parties
    .map(partyName => {
      const { answer, reason } = questions[questionIndex]
        .answers
        .find(({ party }) => party === partyName);
      return {
        partyName,
        answer,
        reason,
      };
    }),
});

export default connect(mapStateToProps)(PartyAnswers);