import { createGlobalStyle } from 'styled-components';
import * as fonts from '../../assets/fonts';

const font = (name, { woff, woff2 }, style = 'normal', weight = 'normal') => `
  @font-face {
    font-family: ${name};
    font-style: ${style};
    font-weight: ${weight};
    font-display: optional;
    src: url('${woff2}') format('woff2'),
         url('${woff}') format('woff');
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${font('biotif', fonts.biotif)}
  ${font('biotif', fonts.biotifBold, 'normal', 'bold')}
  ${font('biotif', fonts.biotifBlack, 'normal', '900')}

  html {
    padding:0
    margin: 0;
    background: #039;
    height: 100%;
  }

  body {
    font-family: -apple-system, system-ui, sans-serif;
    font-size: 16px;
    font-feature-settings: "ss01";
    line-height: 1.4;
    margin: 0 auto;
    padding: 1rem;
    box-sizing: border-box;
    min-height: 100%;

    @media (min-width: 50rem) {
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }
  
  a {
    color: ${props => props.theme.color.europeBlue};
    
    &:hover {
      color: black;
      background: ${props => props.theme.color.europeYellow};
    }
  }
`;

export default GlobalStyle;