import React, { Children } from 'react';
import styled from 'styled-components';

const ChildContainer = styled.div``;

const SplitContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  
  @media (max-width: 29.9375rem) {
    ${ChildContainer} + ${ChildContainer} {
      margin-top: .5rem;
    }
  }
  
  @media (min-width: 30rem) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Split = ({ children }) => (
  <SplitContainer>
    {Children.map(children, child => <ChildContainer>{child}</ChildContainer>)}
  </SplitContainer>
);

export default Split;