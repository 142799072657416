import styled from 'styled-components';
import PropTypes from 'prop-types';

export const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.4;
  word-break: break-word;
  hyphens: auto;
  text-align: ${props => props.textAlign};
  
  &:last-child {
    margin-bottom: 0;
  }
`;

Paragraph.propTypes = {
  textAlign: PropTypes.oneOf([ 'left', 'right' ]),
};

Paragraph.defaultProps = {
  textAlign: 'left',
};

export default Paragraph;