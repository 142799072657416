import { SET_PARTIES } from '../actions';

/*
  shape
  [
    String,
    ...
  ]
 */
const defaultState = [];

const parties = (state = defaultState, { type, parties }) => {
  switch (type) {
    case SET_PARTIES:
      return parties.map(String);
    default:
      return state;
  }
};

export default parties;