import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const buttonCSS = css`
  display: inline-block;
  font-size: 1rem;
  color: white;
  padding: .5rem 1.25rem;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;
  background: ${props => props.theme.color.europeBlue};
  border: none;
  border-radius: .25rem;
  font-weight: bold;
  transition: background 150ms ease-out, color 150ms ease-out;
  touch-action: manipulation;
  
  &:hover {
    background: ${props => props.theme.color.europeYellow};
    color: black;
  }
  
  @media (hover: none) {
    &:hover {
      background: ${props => props.theme.color.europeBlue};
      color: inherit;
    }
  }
`;

const DefaultButton = styled.button`
  ${buttonCSS}
`;

const LinkButton = styled(Link)`
  ${buttonCSS}
`;

export const Button = ({ className, children, to, onClick, type }) => {
  const ContainerComponent = to ? LinkButton : DefaultButton;
  return (
    <ContainerComponent
      className={className}
      to={to}
      onClick={onClick}
      type={type}
    >
      {children}
    </ContainerComponent>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  onclick: PropTypes.func,
  type: PropTypes.string,
};

Button.defaultProps = {
  className: null,
  to: null,
  onclick: null,
  type: null,
};

export default Button;