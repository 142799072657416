import {
  SET_QUESTIONS,
  SET_ANSWER,
  TOGGLE_DOUBLE_ANSWER,
  SET_ALL_ANSWERS,
  RESET_ALL_ANSWERS
} from '../actions';

const defaultState = [];

const answers = (state = defaultState, { type, questions, index, value, answers }) => {
  switch (type) {
    case SET_QUESTIONS:
      return state.length === questions.length
        ? state
        : new Array(questions.length)
          .fill({ double: false, answer: 'skipped' });
    case TOGGLE_DOUBLE_ANSWER:
      return state
        .map(({ double, answer }, currentIndex) =>
          currentIndex === index
            ? { double: !double, answer }
            : { double, answer }
        );
    case SET_ANSWER:
      return state
        .map(({ double, answer }, currentIndex) =>
          currentIndex === index
            ? { double, answer: value }
            : { double, answer }
        );
    case SET_ALL_ANSWERS:
      return answers;
    case RESET_ALL_ANSWERS:
      return state
        .map(() => ({ double: false, answer: 'skipped' }));
    default:
      return state;
  }
};

export default answers;