import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

import { VisuallyHidden } from '../../meta';

const bulletHighlightCss = css`
    &::before {
      content: ' ';
      display: block;
      width: 6px;
      height: 6px;
      background: ${props => props.theme.color.europeYellow};
      border-radius: 50%;
      position: absolute;
      top: 100%;
      left: calc(50% - 3px);
    }
`;

const Container = styled.div`
  margin: 0 2rem 2rem;
  text-align: center;
`;

const BulletContainer = styled.span`
  position: relative;
  ${props => props.double && bulletHighlightCss};
  
  & + & {
    margin-left: .125rem;
  }
`;

BulletContainer.propTypes = {
  double: PropTypes.bool.isRequired,
};

const Bullet = styled(Link)`
  text-decoration: none;
  border: 2px solid transparent;
  font-size: .5rem;
  background: ${props => props.answer && ((props.theme.color)[props.answer] || 'grey')};
  border-radius: 50%;
  width: 6px;
  height: 6px;
  display: inline-block;
  transition: transform 250ms ease-out;
  transform: ${props => props.answer === 'skipped' && 'scale(.5)'};
  
  &[aria-selected=true] {
    border-color: rgba(0, 0, 0, .4);
    background: ${props => props.answer === 'skipped' && 'black'};
  }
`;

export class Overview extends Component {
  static propTypes = {
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        answer: PropTypes.oneOf([ 'approval', 'neutral', 'negative', 'skipped' ]),
      })
    ).isRequired,
    index: PropTypes.number.isRequired,
  };

  // TODO: move this to a sensible location
  componentWillReceiveProps({ answers }) {
    localStorage.setItem('answers', JSON.stringify(answers));
  }

  render () {
    const { answers, index } = this.props;
    return (
      <Container>
        {answers.map(({ answer, double }, answerIndex) => {
          const humanReadableIndex = answerIndex + 1;
          return (
            <BulletContainer
              key={answerIndex}
              double={double}
            >
              <Bullet
                to={`/questions/${humanReadableIndex}`}
                aria-selected={index === answerIndex}
                answer={answer}
              >
                <VisuallyHidden>
                  {{
                    approval: `Zugestimmt für These ${humanReadableIndex}`,
                    neutral: `Neutrale Meinung️ zu These ${humanReadableIndex}`,
                    negative: `Ablehnung für These ${humanReadableIndex}`,
                    skipped: `These ${humanReadableIndex} wurde übersprungen`,
                  }[answer]}
                </VisuallyHidden>
              </Bullet>
            </BulletContainer>
          );
        })}
      </Container>
    );
  }
}

const mapStateToProps = ({ answers }) => ({
  answers,
});

export default connect(mapStateToProps)(Overview);