import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  border-radius: .5rem;
  padding: .5rem;
  margin: 1rem 0;
  flex-wrap: wrap;
  box-shadow: 0 4px 8px rgba(0,0,0,.1);
`;

const Description = styled.div`
  width: 100%;
  padding: 0 0 .5rem;
`;

const Code = styled.div`
  flex-grow: 1;
  font-family: 'Fira Code', monospace;
  white-space: pre;
  background: hsl(0, 0%, 98%);
  border-radius: .25rem;
  padding: .25rem;
  margin-right: 1rem;
  box-sizing: border-box;
  width: calc(50% - 1rem);
`;

const Demo = styled.div`
  flex-grow: 1;
  padding: .25rem;
  box-sizing: border-box;
  width: 50%;
`;

export const ComponentDoc = ({ code, children, node }) => (
  <Container>
    {children && (
      <Description>
        {children}
      </Description>
    )}
    <Code>
      {code}
    </Code>
    <Demo>
      {node}
    </Demo>
  </Container>
);

ComponentDoc.propTypes = {
  code: PropTypes.string.isRequired,
  node: PropTypes.node.isRequired,
};

export default ComponentDoc;