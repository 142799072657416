import React from 'react';

import { Card } from '../layout';
import { H1, Paragraph } from '../text';
import { Button } from '../input';

export const Imprint = () => (
  <Card style={{ maxWidth: '50rem' }}>
    <H1>Impressum</H1>
    Florian Meinicke<br/>
    Grüntenweg 2<br/>
    90471 Nürnberg<br/><br/>
    Beschwerden an: <a href="mailto:wahlomat@flo.codes">wahlomat@flo.codes</a>
    <H1>Datenschutz</H1>
    <Paragraph>
      Hier wird nichts getrackt.<br/>
      Kein Google Analytics, keine Werbung, kein Garnichts.<br/>
      Und Deine Eingaben? Sehe ich nicht. Alles passiert bei Dir auf dem Client (deinem Browser, Firefox, Chrome etc.),
      so, wie es sein sollte. Damit habe ich gar nicht die Möglichkeit, deine beschämenden Ergebnisse zu sehen.
    </Paragraph>
    <Button to="/questions/1">Okay! Ab zum Fragebogen!</Button>
  </Card>
);

export default Imprint;