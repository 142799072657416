/*
    Combination of all reducers
*/

import { combineReducers } from 'redux';

import parties from './parties';
import questions from './questions';
import answers from './answers';

const voteApp = combineReducers({
  questions,
  parties,
  answers,
});

export default voteApp;