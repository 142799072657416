import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Question from './Question';
import Overview from './Overview';
import Results, { PartyAnswers } from '../../results';
import { Card, ScrollContainer } from '../../layout';
import { InternalLink } from '../../input';

const scrollShadowCSS = css`
  background-image:
    linear-gradient(to bottom, ${props => props.canScrollUp ? '#d6d6d6' : 'white'}, white 1rem),
    linear-gradient(to top, ${props => props.canScrollDown ? '#d6d6d6' : 'white'}, white 1rem);
  background-size: 100% 1rem;
  background-repeat: no-repeat;
  background-position: top, bottom;
  transition: background 250ms ease-out;
  
  > * {
    mix-blend-mode: multiply;
  }
`;

const ImprintLink = styled(InternalLink)`
  display: inline-block;
  margin: 0 2rem 2rem;

  @media (min-width: 50rem) {
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    padding: 0;
    margin: 0;
  }
`;

const QuestionContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  grid-area: questions;
`;

const PartyAnswersContainer = styled.div`
  width: 100%;
  padding: 2rem;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  grid-area: answers;
  ${scrollShadowCSS};
`;

const ResultsContainer = styled.div`
  width: 100%;
  padding: 2rem;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  grid-area: results;
  ${scrollShadowCSS};
`;

const Container = styled(Card)`
  overflow: hidden;
  padding: 0 !important;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "questions"
    "results"
    "answers"
  ;
  max-width: 70rem;
  
  @media (min-width: 50rem) and (min-height: 40rem) {
    position: fixed;
    grid-template-columns: 1fr 20rem;
    grid-template-areas:
      "questions results"
      "answers   results"
    ;
    max-width: none;
    width: calc(100vw - 2rem);
    left: 1rem;
    height: calc(100vh - 2rem);
    top: 1rem;
    
    ${PartyAnswersContainer} {
      border-top: 1px solid #666;
    }
    
    ${ResultsContainer} {
      border-left: 1px solid #666;
    }
  }
`;

const Index = ({ match: { params: { questionIndex: index } }, history: { push } }) => {
  const questionIndex = parseInt(index) - 1;
  return (
    <Container>
      <QuestionContainer>
        <Question
          questionIndex={questionIndex}
          push={push}
        />
        <Overview index={questionIndex}/>
      </QuestionContainer>
      <ScrollContainer>
        <PartyAnswersContainer>
          <PartyAnswers questionIndex={questionIndex}/>
        </PartyAnswersContainer>
      </ScrollContainer>
      <ScrollContainer>
        <ResultsContainer>
          <Results/>
        </ResultsContainer>
      </ScrollContainer>
      <div>
        <ImprintLink to="/imprint">Impressum</ImprintLink>
      </div>
    </Container>
  );
};

Index.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      questionIndex: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Index;