import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setParties, setQuestions, setAllAnswers } from '../../actions';
import { ComponentLibrary, Home, Questionnaire, Imprint } from '../routes';

const answers = import('../../assets/answers.json');

class App extends  Component {
  static propTypes = {
    isLoaded: PropTypes.bool.isRequired,
    setQuestions: PropTypes.func.isRequired,
    setParties: PropTypes.func.isRequired,
  };

  async componentDidMount() {
    const { setParties, setQuestions, setAllAnswers } = this.props;
    const { questions, parties } = await answers;
    if (this._didUnmount) return;

    try {
      localStorage.answers && setAllAnswers(JSON.parse(localStorage.answers));
    } catch(e) {}

    setParties(parties);
    setQuestions(questions);
  }

  componentWillUnmount() {
    this._didUnmount = true;
  }

  render () {
    const { isLoaded } = this.props;
    if (!isLoaded) return <span style={{ color: 'white' }}>Loading…</span>;
    return (
      <Router>
        <Route path="/" exact component={Home} />
        <Route path="/components" exact component={ComponentLibrary} />
        <Route path="/imprint" exact component={Imprint} />
        <Route path="/questions/:questionIndex" component={Questionnaire} />
      </Router>
    );
  }
}

const mapStateToProps = ({ questions, answers }) => ({
  isLoaded: !!questions.length && !!answers.length,
});

const mapDispatchToProps = dispatch => ({
  setParties: parties => dispatch(setParties(parties)),
  setQuestions: questions => dispatch(setQuestions(questions)),
  setAllAnswers: answers => dispatch(setAllAnswers(answers)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
