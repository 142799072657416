import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { toggleDoubleAnswer } from '../../../actions';
import { SmallText } from '../../text';

const ToggleContainer = styled.div`
  margin-bottom: .5rem;
`;

const Toggle = styled.button`
  cursor: pointer;
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0 auto 0;
  font-size: 1.25rem;
  vertical-align: middle;
  color: ${props => props.value ? props.theme.color.europeYellow : props.theme.color.europeBlue};
`;

const Label = styled(SmallText)`
  vertical-align: middle;
  display: inline-block;
  margin-left: .25rem;
`;

export const ImportanceToggle = ({ questionIndex, value, toggle }) => (
  <ToggleContainer>
    <Toggle onClick={() => toggle(questionIndex)} value={value}>
      {value ? '★' : '☆'}
    </Toggle>
    <Label>Doppelt werten</Label>
  </ToggleContainer>
);

ImportanceToggle.propTypes = {
  questionIndex: PropTypes.number.isRequired,
  value: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

const mapStateToProps = ({ answers }, { questionIndex }) => ({
  value: answers[questionIndex].double,
});

const mapDispatchToProps = dispatch => ({
  toggle: index => dispatch(toggleDoubleAnswer(index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportanceToggle);