export const SET_QUESTIONS = 'SET_QUESTIONS';
export const SET_PARTIES = 'SET_PARTIES';
export const SET_ANSWER = 'SET_ANSWER';
export const SET_ALL_ANSWERS = 'SET_ALL_ANSWERS';
export const TOGGLE_DOUBLE_ANSWER = 'TOGGLE_DOUBLE_ANSWER';
export const RESET_ALL_ANSWERS = 'RESET_ALL_ANSWERS';

export const setQuestions = questions => ({
  type: SET_QUESTIONS,
  questions,
});

export const setParties = parties => ({
  type: SET_PARTIES,
  parties,
});

export const setAllAnswers = (answers) => ({
  type: SET_ALL_ANSWERS,
  answers,
});

export const setAnswer = (index, value) => ({
  type: SET_ANSWER,
  index,
  value,
});

export const toggleDoubleAnswer = index => ({
  type: TOGGLE_DOUBLE_ANSWER,
  index,
});

export const resetAllAnswers = () => ({
  type: RESET_ALL_ANSWERS,
});