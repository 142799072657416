const color = {
  europeBlue: '#039',
  europeYellow: '#FFCC00',
  approval: '#40ae27',
  neutral: '#666',
  negative: '#c74a25',
};

const standard = {
  color,
};

export default standard;