import React from 'react';

import { Button, InternalLink } from '../input';
import { Card, List, ListItem, Split } from '../layout';
import { H1, Paragraph } from '../text';

const Home = () => (
  <Card style={{ maxWidth: '50rem' }}>
    <H1>
      Bundestagswahl 2021<br/>
      alternativer Wahl&#8209;O&#8209;Mat
    </H1>
    <Paragraph>
      Ich habe die Daten des Wahl-O-Maten befreit und in ein neues Interface geklatscht, welches
      weniger Einschränkung nicht besitzt. Zudem kann man auch gleich alle Begründungen der Parteien sehen, während man seine
      Antworten gibt und hat eine Live-Vorschau seines aktuellen Ergebnisses.
    </Paragraph>
    <Paragraph>
      Bitte beachte folgende Punkte, bevor Du fortfährst:
    </Paragraph>
    <List>
      <ListItem>Diese Anwendung wurde noch <strong>nicht gründlich getestet</strong>! Fehler sind wahrscheinlich!
        </ListItem>
      <ListItem>Schau vielleicht beim <a href="https://www.wahl-o-mat.de/bundestagswahl2021" rel="noreferrer">originalen
        Wahl&#8209;O&#8209;Mat für die Bundestwagswahl 2021</a> vorbei.</ListItem>
      <ListItem>Dies ist keine Wahlempfehlung, sondern lediglich eine Informationshilfe.</ListItem>
      <ListItem>Alle Daten sind 1:1 vom Wahl&#8209;O&#8209;Mat <strong>geklaut</strong>. Bitte verpfeif mich nicht, falls das
        irgendwie verboten sein sollte. <span role="img" aria-label="Schulterzucken">🤷️</span></ListItem>
    </List>
    <Split>
      <Button to="/questions/1">Jaja, gib’s mir einfach!</Button>
      <InternalLink to="/imprint">Impressum</InternalLink>
    </Split>
  </Card>
);

export default Home;