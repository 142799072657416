import styled from 'styled-components';

export const Card = styled.div`
  background: white;
  border-radius: .5rem;
  box-shadow: 0 .25rem 2rem rgba(0, 0, 0, 0.5);
  position: relative;
  padding: 1.25rem;
  margin: 0 auto;
  box-sizing: border-box;
  
  @media (min-width: 50rem) {
    padding: 2.5rem;
  }
`;

export default Card;