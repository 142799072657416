import { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';

export class ScrollContainer extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired, // only a single child is supported
  };

  state = {
    canScrollDown: false,
    canScrollUp: false,
  };

  updatePosition = () => {
    if (!this._ref) return;
    const canScrollUp = this._ref.scrollTop !== 0;
    const canScrollDown = this._ref.scrollTop !== this._ref.scrollHeight - this._ref.clientHeight;
    this.setState({
      canScrollUp,
      canScrollDown,
    });
  };

  addHandler() {
    this._ref && this._ref.addEventListener('scroll', this.updatePosition);
    window && window.addEventListener('resize', this.updatePosition);
  }

  removeHandler() {
    this._ref && this._ref.removeEventListener('scroll', this.updatePosition);
    window && window.removeEventListener('resize', this.updatePosition);
  }

  handleRef = ref => {
    this.removeHandler();
    this._ref = ref;
    this.addHandler();
    this.updatePosition();
  };

  componentWillUnmount() {
    this.removeHandler();
  }

  render () {
    const { children } = this.props;
    const { canScrollDown, canScrollUp } = this.state;
    const cloneProps = {
      ref: this.handleRef,
      canScrollDown,
      canScrollUp,
    };
    return cloneElement(children, cloneProps);
  }
}

export default ScrollContainer;